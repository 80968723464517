:root {
  --bg-light: #f4f4f8;
  --bg-card: #ffffff;
  --text-primary: #2c3e50;
  --text-secondary: #7f8c8d;
  --accent-color: #3498db;
  --border-radius: 12px;
  --box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  --no-preview-bg: #e9ecef;
}

.archives-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--bg-light);
}

.page-title {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.filter-dropdown {
  width: 350px;
  margin: 0 auto 2rem;
  display: block;
  padding: 12px 15px;
  font-size: 1rem;
  border: 2px solid var(--accent-color);
  border-radius: var(--border-radius);
  background-color: var(--bg-card);
  transition: all 0.3s ease;
}

.archive-section {
  margin-bottom: 3rem;
}

.section-title {
  color: var(--text-primary);
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 0.5rem;
}

.archive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2rem;
}

.archive-item {
  background-color: var(--bg-card);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.archive-thumbnail {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.archive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.archive-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.archive-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.archive-title:hover {
  color: darken(var(--accent-color), 10%);
}

.archive-description {
  color: var(--text-secondary);
  margin-bottom: 1rem;
  font-size: 1rem;
  flex-grow: 1;
}

.archive-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: auto;
}

.category-tag {
  background-color: var(--bg-light);
  color: var(--text-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.no-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--no-preview-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}

.no-preview span {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 1rem;
}

.loading-spinner,
.no-archives,
.error-message {
  text-align: center;
  color: var(--text-secondary);
  padding: 2rem;
  font-size: 1.2rem;
}

.error-message {
  color: #e74c3c;
}

@media (max-width: 1200px) {
  .archive-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

@media (max-width: 768px) {
  .archives-container {
    padding: 1rem;
  }

  .archive-grid {
    grid-template-columns: 1fr;
  }

  .filter-dropdown {
    width: 100%;
  }
}