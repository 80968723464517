.archive-item {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .filter-dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
  }
  
  .filter-dropdown:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
  }
  
  
  .archive-preview {
    width: 100%;
    height: auto;
    max-height: 150px; /* Adjust as needed */
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .no-preview {
    width: 100%;
    height: 150px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    color: #888;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .filter-dropdown {
    margin-bottom: 20px;
  }
  