.home-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.image-container {
  width: 100%;
  height: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
  opacity: 1;
}

.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.home-content {
  max-width: 800px;
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  padding-right: 10px;
}

.home-content::-webkit-scrollbar {
  width: 8px;
}

.home-content::-webkit-scrollbar-track {
  background: transparent;
}

.home-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  text-align: center;
  font-style: italic;
}

#home-para {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: white;
  text-align: justify;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .home-content {
      max-width: 90%;
      height: 50vh;
  }

  h1 {
      font-size: 2.5rem;
  }

  h2 {
      font-size: 1.8rem;
  }

  #home-para {
      font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .home-overlay {
      padding: 10px;
  }

  .home-content {
      max-width: 95%;
      height: 45vh;
  }

  h1 {
      font-size: 2rem;
  }

  h2 {
      font-size: 1.5rem;
  }

  #home-para {
      font-size: 0.9rem;
  }
}