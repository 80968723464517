header {
  background: url('https://www.thethirdpole.net/content/uploads/2021/02/DT865R.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 20px 0;
}

header::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

nav {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

nav li {
  display: inline;
}

nav a {
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  position: relative;
}

.search-form {
  display: flex;
  align-items: center;
}

.search-input,
.search-button {
  padding: 8px 12px;
  font-size: 1em;
  border: 1px solid #ddd;
  margin-right: 4px;
}

.search-input {
  flex-grow: 1;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
}

.search-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.search-input:focus,
.search-button:focus {
  outline: none;
}

.search-input {
  padding-right: 40px;
}

.smaller {
  width: 100px;
}

/* Updated dropdown styles */
.dropdown {
  display: block;
  position: absolute;
  background-color: rgba(240, 240, 240, 0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

li:hover .dropdown {
  opacity: 1;
  visibility: visible;
}

.dropdown li {
  float: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  transition: background-color 0.2s ease;
}

.dropdown a {
  color: #333;
  text-shadow: none;
}

.dropdown li:hover {
  background-color: rgba(208, 208, 208, 0.9);
}

.archives-menu {
  position: relative;
}

.archives-menu .dropdown {
  top: 100%;
  left: 0;
}

/* Logout button style */
.logout {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.logout:hover {
  background-color: #c82333;
}