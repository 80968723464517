.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.heading {
  text-align: center;
  margin-bottom: 30px;
}

.upload-item {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.upload-item h3 {
  margin-top: 0;
}

.upload-item p {
  margin-bottom: 10px;
}

.upload-item button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.upload-item button:hover {
  background-color: #0056b3;
}