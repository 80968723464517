.button-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the value to increase or decrease the space between buttons */
    margin-top: 20px;
  }
.button-container button {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button-container .verify-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .button-container .reject-btn {
    background-color: #f44336;
    color: white;
  }