.confirm-upload {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .confirm-upload h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .confirm-upload p {
    margin-bottom: 10px;
  }
  
  .filter-dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .filter-dropdown:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
  }
  
  .upload-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  
  .upload-btn:hover {
    background-color: #0056b3;
  }