/* dropzone.css */

.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  width: 50%;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  color: #888;
}

.button-container {
  margin-top: 20px; /* Add spacing between input box and button */
}

button {
  width: 150px;

  /* Add other button styles if needed */
}