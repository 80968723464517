.video-container {
    position: relative;
    width: 100%; /* Full width of the container, but not larger than the max-width */
    max-width: 640px; /* Adjust this value based on your design needs */
    margin: auto; /* Center align the video container */
}

.video-container video {
    width: 100%; /* Makes the video responsive */
    height: auto; /* Maintain the aspect ratio */
}
